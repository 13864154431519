import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SiteProtectionComponent } from './shared/components/site-protection/index.protected';
import { environment } from '@environment';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, NavbarComponent, SiteProtectionComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'portal';
    environment = environment.environment;
}
