<navbar></navbar>
<main>
    <router-outlet />
</main>

@defer (when environment === 'production') {
    <site-protection />
}

<router-outlet />
