import {
    ApplicationConfig,
    provideExperimentalZonelessChangeDetection,
    isDevMode
} from '@angular/core';
import { provideRouter, withComponentInputBinding, withViewTransitions } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import {
    provideClientHydration,
    withEventReplay,
    withIncrementalHydration
} from '@angular/platform-browser';
import { environment } from '@environment';
import { interceptors } from '@shared/http';

export const appConfig: ApplicationConfig = {
    providers: [
        provideExperimentalZonelessChangeDetection(),
        provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
        provideHttpClient(withFetch(), withInterceptors(interceptors)),
        provideClientHydration(withEventReplay(), withIncrementalHydration()),

        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode() && environment.serviceworker,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        provideAnimations()
    ]
};
