/**
 *  Add language information to all our requests to backend
 * */
import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject, LOCALE_ID } from '@angular/core';

export function languageInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
    const localeID =  inject(LOCALE_ID);
    const parsedLocale = localeID.split('-')[0];

    const newReq = req.clone({
        headers: req.headers.append('lang', parsedLocale)
    });
    return next(newReq);
}
